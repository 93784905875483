<template>
    <b-overlay :show="showOverlay" rounded="sm">
        <div class="login mt-3">
            <b-container class="content">
                <div class="row">
                    <div class="col-md-6 order-md-2">
                        <b-img :src="require('../../assets/img/undraw_file_sync_ot38.svg')" alt="Image"
                               class="img-fluid"/>
                    </div>
                    <div class="col-md-6 contents">
                        <div class="row justify-content-center">
                            <div class="col-md-8 px-4 py-4">
                                <div class="mb-4">
                                    <h3>Sign In to <strong class="fontColor">Bill Generator</strong></h3>
                                    <p class="mb-4">Enter your personal details to login</p>
                                </div>

                                <div class="form-group first">
                                    <label for="Email">Username</label>
                                    <input type="text" v-model="form.email" class="form-control" id="Email">
                                </div>

                                <div class="form-group last mb-4">
                                    <label for="Password">Password</label>
                                    <input v-model="form.password" type="password" class="form-control"
                                           id="Password">
                                </div>
                                <input type="submit" @click="onSubmit" value="Log In"
                                       class="btn text-white mt-4 font-weight-bold btn-block btn-warning">
                            </div>
                        </div>
                    </div>
                </div>
            </b-container>
        </div>
    </b-overlay>
</template>


<script>
    import commonUtl from "../../util/commonUtl";
    import {mapActions} from 'vuex'

    export default {
        data() {
            return {
                showOverlay: false,
                form: {
                    email: '',
                    password: '',
                },
            }
        },
        methods: {
            ...mapActions({
                logIn: 'auth/logIn',
            }),
            async onSubmit(event) {
                event.preventDefault();
                this.showOverlay = true
                this.logIn(this.form)
                    .then(() => {
                        this.$router.replace({
                            name: 'users'
                        })
                    })
                    .catch(async e => {
                        const data = e.response.data;
                        await commonUtl.showNotification(this, 'error', 'Error', data.value)
                    })
                    .finally(() => {
                        this.showOverlay = false
                    })
            },
            onReset(event) {
                event.preventDefault();
                // Reset our form values
                this.form.email = '';
                this.form.password = '';

                // Trick to reset/clear native browser form validation state

            }
        },
        async mounted() {
          localStorage.clear()
        }
    }
</script>

<style scoped>

    .content {
        padding: 4rem 0;
    }

    h2 {
        font-size: 20px;
    }

    @media (max-width: 991.98px) {
        .content .bg {
            height: 500px;
        }
    }

    .content .contents .form-group, .content .bg .form-group {
        position: relative;
    }

    .content .contents .form-group label, .content .bg .form-group label {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease;
    }

    .content .contents .form-group input, .content .bg .form-group input {
        background: transparent;
        border-bottom: 1px solid #ccc;
    }

    .content .contents .form-group.first, .content .bg .form-group.first {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    }

    .content .contents .form-group.last, .content .bg .form-group.last {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
    }

    .content .contents .form-group label, .content .bg .form-group label {
        font-size: 12px;
        display: block;
        margin-bottom: 0;
        color: #b3b3b3;
    }

    .content .contents .form-group.field--not-empty label, .content .bg .form-group.field--not-empty label {
        margin-top: -25px;
    }

    .content .contents .form-control, .content .bg .form-control {
        border: none;
        padding: 0;
        font-size: 20px;
        border-radius: 0;
    }

    .content .contents .form-control:active, .content .contents .form-control:focus, .content .bg .form-control:active, .content .bg .form-control:focus {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .content a {
        color: #888;
        text-decoration: underline;
    }

    .content .btn {
        height: 54px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .content .forgot-pass {
        position: relative;
        top: 2px;
        font-size: 14px;
    }

    .control {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 14px;
    }

    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }


</style>
